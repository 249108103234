import { UseQueryResult } from '@tanstack/react-query';

import { toSorted } from '@/utils/trebleFunctions';

import { AuralizerSimulationDto, TaskResultsForTaskGroup } from '../types';
import { SolveTask } from '@/types';

export const mapToTaskResultsForReceivers = (
  results: UseQueryResult<SolveTask, unknown>[],
  simulations: AuralizerSimulationDto[] | undefined
): TaskResultsForTaskGroup | undefined => {
  if (!simulations) {
    return undefined;
  }

  const initialAcc: TaskResultsForTaskGroup = {};

  const receiverUrls = results.reduce((acc, queryResult, index) => {
    if (!queryResult.data || index >= simulations.length) {
      return acc;
    }

    const orderedSourceResults = toSorted(queryResult.data.sourceResults, (a, b) => a.orderNumber - b.orderNumber);

    orderedSourceResults.forEach((sourceResult) => {
      sourceResult.taskResultForReceiver.forEach((taskResultForReceiver) => {
        const simulationId = simulations[index].id;
        const sourcePointId = sourceResult.sourcePointId;
        const pointId = taskResultForReceiver.pointId;

        const newAcc = { ...acc };
        newAcc[simulationId] = newAcc[simulationId] || {};
        newAcc[simulationId][sourcePointId] = newAcc[simulationId][sourcePointId] || {};
        newAcc[simulationId][sourcePointId][pointId] = {
          url: taskResultForReceiver.receiverResults?.[0]?.uploadUrl ?? '',
          normalizationFactor: taskResultForReceiver.spatialIRNormalization ?? 1,
        };

        acc = newAcc;
      });
    });

    return acc;
  }, initialAcc);

  return receiverUrls;
};
