import {
  SourceSummingResultDto,
  SourceSummingResultForGridReceiverDto,
} from '@/components/Results/components/ResultComparison/hooks';

export type BaseType = {
  id: string;
  name: string;
};

export type SpaceDto = BaseType & {
  description: string;
  tag: string;
  modelBases: ModelBaseDto[];
  name: string;
  id: string;
  projectId: string;
  createdAt: string;
};

export type SpaceDetailsDto = {
  id: string;
  name: string;
  description: string;
  projectId: string;
  createdAt: string;
  modelCount: number;
};

export type ModelBaseDto = BaseType & {
  createdAt: string;
  spaceId: string;
  revisions: number;
  model: Model;
};

export type PartialModelBase = BaseType & Pick<ModelBaseDto, 'createdAt'>;

export type ThumbnailsForModelBase = {
  key: string;
  value: string;
};

export type Model = BaseType & {
  creationTime: string;
  modelBaseId: string;
  modelUploadId: string;
  sourceModelFileUploadId: string;
  sourceModelSha256: string;
  userId: string;
  application: string;
  applicationVersion: string;
  applicationPluginVersion: string;
  nonWatertight: boolean | null;
};

export type Simulation = {
  id: string;
  modelId: string;
  userId: string;
  createdAt: string;
  updatedAt?: string;
  name: string;
  description: string;
  sources: Source[];
  receivers: Receiver[];
  gridReceivers: GridReceiver[];
  modelSettings: ModelSettings | null;
  solverSettings: SolverSettings;
  sourceParameters: SourceParameter[];
  lastSimulationRun?: SimulationRunDto | null;
  taskType: string | null;
  settingsPreset: number | null;
  hasBeenEdited: boolean | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extra?: any;
};

export type ModelSettings = {
  materialIdByObjectId: {
    [key: string]: string;
  };
  scatteringByObjectId: {
    [key: string]: number[];
  };
};

export type Point = {
  orderNumber: number;
  x: number;
  y: number;
  z: number;
  label: string;
  id: string;
};

export type Source = Point;

export type Receiver = Point;

export type GridReceiver = {
  orderNumber?: number;
  label: string;
  id: string;
  gridType: string;
  userInput: GridReceiverUserInput;
  points: GridReceiverPoint[];
  geometryInfo: { [Key: string]: string };
};

export type GridReceiverUserInput = {
  centerPoint: { x: number; y: number; z: number };
  coarseness: number;
  width: number;
  length: number;
  pitch: number;
  yaw: number;
  roll: number;
};

export type GridReceiverPoint = {
  gridIndex: number;
  x: number;
  y: number;
  z: number;
};

export type SourceParameter = {
  sourceId?: string;
  directivityPattern: string;
  azimuth: number;
  elevation: number;
  rotation: number;
  eq: number[] | null;
  overallGain: number | null;
  correctIrByOnAxisSpl: boolean;
  useSplForSti: boolean;
  irTimeDelay: number;
  splOnAxis1m: number[] | null;
  splOnAxis1mByFrequency: { [key: string]: number } | null;
};

export type SolverSettings = {
  dgSettings: DGSettings;
  gaSettings: GASettings;
};

export type GASettings = {
  crossoverFrequency: number;
  impulseLengthSeconds: number;
  frequencyBands: number[];
  rayCount: number;
  maxReflections: number;
  methodType: number;
  receiverDiameter: number;
  reflectionOrder: number;
  generateIR: boolean;
  preferredTaskProvider?: string;
  energyDecayThreshold: number | null;
};

export type DGSettings = {
  crossoverFrequency: number;
  impulseLengthSeconds: number;
  sourceWidth: number | null;
  gpuCount: number;
  basisOrder: number;
  cfl: number;
  precision: number;
  timestepper?: string;
  preferredTaskProvider?: string;
  energyDecayThreshold: number | null;
};

export type TaskType = 'Hybrid' | 'DG' | 'GA' | 'Mesh';
export type TaskRunStatus =
  | 'Created'
  | 'Pending'
  | 'InProgress'
  | 'Cancelling'
  | 'Queued'
  | 'ProcessingResults'
  | 'Completed'
  | 'Cancelled'
  | 'Error'
  | 'Invalid';

export type Task = {
  id: string;
  taskType: TaskType;
  status: TaskRunStatus;
  createdAt?: string;
  startedAt?: string;
  completedAt?: string;
  completedAtEst?: string;
  progressPercentage?: number;
  userErrorMessage?: string;
  errorDetails?: string;
  userStatusMsg?: string;
  taskProvider?: string;
  taskGroupId?: string;
  sourcePointId?: string;
};

export enum RunStatus {
  Created = 'Created',
  InProgress = 'InProgress',
  Queued = 'Queued',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Error = 'Error',
  InsufficientTokens = 'InsufficientTokens',
  TaskError = 'TaskError',
  ProcessingResults = 'ProcessingResults',
}

export type SimulationRunDto = {
  id: string;
  simulationId: string;
  taskType: TaskType;
  createdAt: string;
  completedAt: string | null;
  sources: Source[];
  receivers: Receiver[];
  gridReceivers: GridReceiver[];
  sourceParameters: SourceParameter[];
  modelSettings: ModelSettings | null;
  solverSettings: SolverSettings;
  settingsPreset: number;
  materialInfoById?: object;
  taskGroupId: string;
  tasks: Task[] | null;
  status: RunStatus | null;
  progressPercentage?: number;
};

export type SourceStatus = {
  sourcePointId: string | null;
  progressPercentage: number | null;
  label: string | null;
  etaSeconds: number | null;
  taskStatuses: Array<TaskStatus> | null;
  orderNumber: number;
};

export type SimulationRunStatusDto = {
  id: string;
  status: RunStatus | null;
  createdAt: string;
  completedAt: string;
  progressPercentage: number | null;
  estimatedSimulationTime: number | null;
  timeEstimate: number | null;
  simulationId: string;
  simulationName: string | null;
  modelId: string;
  modelBaseName: string | null;
  spaceId: string | null;
  spaceName: string | null;
  sources: Array<SourceStatus> | null;
};

export type SolveTask = {
  taskGroupId: string;
  sourceResults: SourceResults[];
  incompleteTasks: IncompleteTasks[] | null;
};

export type IncompleteTasks = {
  id: string;
  taskType: ResultType;
  status: StatusType;
  createdAt: string;
  startedAt: string;
  completedAt: string;
  completedAtEst: string;
  progressPercentage: number;
  userErrorMessage: string;
  errorDetails: string;
  userStatusMsg: string;
  taskProvider: string;
  taskGroupId: string;
  sourcePointId: string;
};

export type PointResult = BaseType & {
  coords: string;
};

export type SourceResults = {
  id: string;
  orderNumber: number;
  createdAt: string;
  resultType: ResultType;
  label: string;
  sourceX: number;
  sourceY: number;
  sourceZ: number;
  sourcePointId: string;
  frequencies: number[];
  resultParametersJson: string;
  hasIncompleteParameterResults: boolean;
  taskResultForReceiver: TaskResultForReceiverDto[];
};

export type GridReceiverResultsDto = {
  id: string;
  solveResultId: string;
  orderNumber: number;
  label: string;
  pointId: string;
  gridType: string;
  parametersDownloadUrl: string;
  userInput: GridReceiverUserInput;
  points: GridReceiverPoint[];
};

export type SourceResultForGridReceiverDto = {
  id: string;
  label: string;
  orderNumber: number;
  createdAt: string;
  resultType: ResultType;
  sourceX: number;
  sourceY: number;
  sourceZ: number;
  sourcePointId: string;
  frequencies: number[];
  gridReceiverResults: GridReceiverResultsDto[];
};

export enum ResultType {
  GA = 'GA',
  DG = 'DG',
  Hybrid = 'Hybrid',
  Mesh = 'Mesh',
}

export type ResultTypeOption = { name: string; id: ResultType };

export enum StatusType {
  Created = 'Created',
  Pending = 'Pending',
  InProgress = 'InProgress',
  Cancelling = 'Cancelling',
  ProcessingResults = 'ProcessingResults',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Error = 'Error',
  Invalid = 'Invalid',
  Queued = 'Queued',
  InsufficientTokens = 'InsufficientTokens',
  TaskError = 'TaskError',
}

export type TaskResultForReceiverDto = {
  id: string;
  solveResultId: string;
  orderNumber?: number;
  irNormalization: number;
  spatialIRNormalization?: number;
  label?: string;
  x?: number;
  y?: number;
  z?: number;
  pointId: string;
  resultParametersJson: string;
  receiverResults?: ReceiverResults[];
};

export type ReceiverResults = {
  id: string;
  solveResultPointId: string;
  frequency: number;
  resultType: string;
  uploadId: number;
  uploadUrl: string;
  uploadLinkExpires: string;
};

export type absorptionCoefficientValues = {
  absorption_target: number[];
  material_fit_id: string;
  material_type: number;
  membrane: boolean;
  quick: boolean;
};

export type MaterialDto = {
  id: string;
  name: string;
  description: string;
  category: string;
  materialJson: string;
  materialMetadataJson: string;
  isUserCreated: boolean;
  isSharedWithOrganization: boolean;
  isDeleted: boolean;
  defaultAbsorption: number;
  defaultScattering: number;
  absorptionCoefficients?: number[];
  userId: string;
  userEmail: string | null;
};

export type Material = {
  id: string;
  name: string;
  description: string;
  category: string;
  materialJson: string;
  materialMetadataJson: string;
  isUserCreated: boolean;
  isSharedWithOrganization: boolean;
  isDeleted: boolean;
  defaultAbsorption: number;
  defaultScattering: number;
  absorptionCoefficients?: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matJson?: any;
  userEmail: string | null;
  userId: string;
};

export type MaterialFit = {
  material_fit_id: string;
  membrane: boolean;
  quick: boolean;
  material_type: number;
  absorption_target: number[];
};

export type SourceDefinition = {
  id: string;
  name: string;
  userEmail: string | null;
  userId: string;
  category: string;
  subCategory: string;
  description: string;
  manufacturer: string;
  isUserCreated: boolean;
  isSharedWithOrganization: boolean;
  isDeleted: boolean;
  isDefault: boolean;
  correctIrByOnAxisSplDefault: boolean;
  useSplForStiDefault: boolean;
  directivity2dJsonDownloadUrl: string;
};

export type SimulationData = {
  selectedGridReceiverObjects?: GridReceiverResultsDto[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridReceiverParameterValues?: Record<string, Record<string, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridReceiverParameterValuesForSummedSources?: Record<string, Record<string, any>>;
  selectedReceiverObjects?: TaskResultForReceiverDto[];
  selectedSourceObject?: SourceResults | SourceResultForGridReceiverDto;
  selectedSourceSummingObject?: SourceSummingResultDto | SourceSummingResultForGridReceiverDto;
};

export type ModelInformationDto = {
  id: string;
  projectId: string;
  projectName: string;
  spaceId: string;
  spaceName: string;
  spaceTag: string;
  modelBaseId: string;
  modelBaseName: string;
  modelBaseCreatedAt: string;
  modelUploadId: number;
  modelUrl: string;
  thumbnailUploadId: number;
  thumbnailUrl: string;
  lastUpdated: string;
  simulationCount: number;
  nonWatertight: boolean | null;
  isProjectShared: boolean | null;
};

export type SelectedModel = {
  id: string;
  name: string;
  modelBaseId: string;
  thumbnail?: string;
  simulations: Simulation[];
  modificationDate: string;
  creationDate: string;
  modelUploadId?: string;
};

export type IActions = {
  value: string;
  disabled?: boolean;
  hidden?: boolean;
  key: string;
  title?: string;
  icon?: React.ReactElement | undefined;
  customClassName?: string;
  renderOption?: React.ReactElement;
  closeOnClick?: boolean;
  onClick: (() => void) | (() => Promise<void>);
};

export type SimulationOverview = {
  simulationId: string;
  simulationName: string;
  modelId: string;
  modelBaseName: string;
  simulationRunId: string;
  taskStatuses: TaskStatus[];
};

export type TaskStatus = {
  taskId: string;
  status: TaskRunStatus;
  taskType: string;
  progressPercentage: number;
  userStatusMsg: string;
  userErrorMsg: string;
  createdAt: string;
  updatedAt: string;
  completedAt: string;
  runtimeSeconds: number;
  sourcePointId: string;
};

export type IconProps = {
  fill?: string;
  fillExtra?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any;
  height?: string;
  width?: string;
  opacity?: string;
};

export type MaterialLayer = {
  name: string;
  layerIndex: number;
  type: string;
  children: MaterialLayer[];
  absorptionCoefficients?: number[];
  materialId?: string | null;
  materialName?: string;
  scatter: number[] | null;
  textName: string;
  id: string;
  parentId: string | null;
  layerGroupIndex: number;
  isMissingMaterial: boolean;
};

export type HiddenLayer = {
  type: 'Layer' | 'LayerGroup' | 'Mesh';
  id: string;
};

type MaterialData = {
  // The json string contains more properties, but not used at the moment
  FittedAbsorptionCoefficients: number[];
};

export type MaterialMetadata = {
  AbsorptionClass: string;
  ImagReflectionCoefficient: number[];
  RealReflectionCoefficient: number[];
  RealSurfaceImpedance: number[];
  ImagSurfaceImpedance: number[];
  InputImagReflectionCoefficient: number[];
  InputImagSurfaceImpedance: number[];
  InputRealReflectionCoefficient: number[];
  InputRealSurfaceImpedance: number[];
  MaterialFitterVersion: string;
};

export type FitMaterial = {
  material_fit_id: string;
  input_parameters_json: string | null;
  input_parameters: MaterialFit | null;
  material_json: string | null;
  material: MaterialData | null;
  material_metadata_json: string | null;
  material_metadata: MaterialMetadata | null;
  status: number;
  material_id: string;
};

// TODO: find a better place when we have restructured
export type ModelSimulationsDto = {
  modelBaseName: string;
  modelId: string;
  modelCreatedAt: string;
  simulations: Simulation[];
};

export type ProjectAndUsersDto = {
  id: string;
  name: string;
  description: string;
  isDeleted: boolean;
  createdAt: string;
  createdBy: string;
  projectUsers: UserBasicDto[];
  spacesCount: number;
};

export type UserBasicDto = {
  id: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  status: 'Active' | 'Disabled' | null;
  createdAt: string;
};

export type SourceDefinitionImportStatusDto = {
  sourceDefinitionId: string;
  sourceDefinitionName: string;
  importStatus: TaskStatus;
};
