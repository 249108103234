import { FC } from 'react';

import { Text } from '@/components/Shared/Text';
import { TrblArrowLeft, TrblArrowRight } from '@/components/Icons';

/** Style */
import styles from './styles.module.scss';

export const ReceiversStep: FC = () => {
  return (
    <div className={styles['step']}>
      <div className={styles['controls']}>
        <div className={`${styles['button']} ${styles['left']}`}>
          <TrblArrowLeft />
        </div>
        <div className={`${styles['button']} ${styles['right']}`}>
          <TrblArrowRight />
        </div>
      </div>
      <Text type="regular-12px" style={{ textAlign: 'center', lineHeight: '160%', letterSpacing: '0.24px' }}>
        Change listening positions by clicking the arrows. You can also select a position from the dropdown menu.
      </Text>
    </div>
  );
};
