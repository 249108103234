import { AudioObject } from '@/components/Auralizer/types';
import { createConvolver } from '@/components/Auralizer/utils/createConvolver';
import { deinterleaveBuffers } from '@/components/Auralizer/utils/deinterleaveBuffers';
import { StandaloneAudioEngine } from '../StandaloneAudioEngine';

export const createConvolverPromise = (
  url: string,
  firstSimNormMax: number,
  sourceNormFactor: number
): Promise<unknown> => {
  const audioObject: AudioObject = {
    merger: null,
    inputGain: null,
    convolverArray: [],
    channelCount: 9,
    sampleRate: 32000,
  };

  const audioEngine = StandaloneAudioEngine.getInstance();

  audioObject.merger = audioEngine.audioContext.createChannelMerger(audioObject.channelCount);
  audioObject.inputGain = audioEngine.audioContext.createGain();

  const ratio = sourceNormFactor / firstSimNormMax;

  const originalGain = 1;

  const newGain = ratio * originalGain;

  audioObject.inputGain.gain.value = newGain;
  audioObject.inputGain.channelCount = 1;

  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const channelBuffers = deinterleaveBuffers(arrayBuffer);

        if (channelBuffers) {
          channelBuffers.buffers.forEach((buffer, index) => {
            const convolver = createConvolver(
              buffer,
              channelBuffers.datatype,
              audioObject.sampleRate,
              audioEngine.audioContext
            );

            audioObject.convolverArray[index] = convolver;

            if (audioObject.inputGain && audioObject.merger) {
              // connect convolver channel to input node
              audioObject.inputGain.connect(audioObject.convolverArray[index]);
              // connect convolver channel to merger
              audioObject.convolverArray[index].connect(audioObject.merger, 0, index);
            }
          });
        }

        resolve(audioObject);
      })
      .catch((error) => reject(new Error(error)));
  });
};
