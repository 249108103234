import { FC } from 'react';

import { CollapsibleInfo } from '@/components/Shared';
/** Componenets */
import { TrblAuralizeIcon } from '@/components/Icons';

import styles from './styles.module.scss';

/** Images */
import trebleLogoWithTextUrl from '@/images/treble-logo-with-text.svg';

type HeaderProps = {
  presetName: string;
  presetDescription: string;
};

export const Header: FC<HeaderProps> = ({ presetName, presetDescription }) => {
  return (
    <div className={styles['header-container']}>
      <div className={styles['logo']}>
        <img src={trebleLogoWithTextUrl} alt="Treble - Auralizer" />
      </div>
      <div className={styles['title']}>
        <TrblAuralizeIcon fill={'#DADADA'} />
        <h2> {presetName}</h2>
      </div>
      <div className={styles['right-placeholder']}>
        {presetDescription.length > 0 && (
          <CollapsibleInfo>
            <p>{presetDescription}</p>
          </CollapsibleInfo>
        )}
      </div>
    </div>
  );
};
