import { FC, useEffect, useState } from 'react';

import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblSelect } from '@/components/Shared/TrblSelect';
import { useCalculateOpenOfficeParameters } from './useCalculateOpenOfficeParameters';

import { AVAILABLE_NC_CURVES } from './constants';

import { ParsedParameterData } from '../ParameterResults/types';

import styles from './styles.module.scss';

type OpenOfficeParametersProps = {
  parameterData: ParsedParameterData[];
};

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#313131',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: 0,
}));

export const OpenOfficeParameters: FC<OpenOfficeParametersProps> = ({ parameterData }) => {
  const [ncCurvesDict, setNcCurvesDict] = useState<Record<string, string>>(
    parameterData.reduce((acc, comparison) => {
      acc[comparison.color] = 'NC30';
      return acc;
    }, {} as Record<string, string>)
  );

  const openOfficeParameters = useCalculateOpenOfficeParameters(parameterData, ncCurvesDict);

  useEffect(() => {
    const ncCurvesDictKeys = Object.keys(ncCurvesDict);
    const ncCurvesDictLength = ncCurvesDictKeys.length;

    const newNcCurvcesDict = { ...ncCurvesDict };

    if (parameterData.length > ncCurvesDictLength) {
      // We added a new comparison
      parameterData.forEach((comparison) => {
        // Check which comparison does not have a NC values in the dict and add the default value
        if (!ncCurvesDictKeys.includes(comparison.color)) {
          newNcCurvcesDict[comparison.color] = 'NC30';
        }
      });
    } else {
      // We removed a comparison
      ncCurvesDictKeys.forEach((key) => {
        // Check which comparison does not have a NC values in the dict and add the default value
        if (!parameterData.map((comparison) => comparison.color).includes(key)) {
          delete newNcCurvcesDict[key];
        }
      });
    }

    setNcCurvesDict(newNcCurvcesDict);
  }, [parameterData.length]);

  const handleNcCurveChanged = (value: string, comparisonColor: string) => {
    const newNcCurvesDict = { ...ncCurvesDict };
    newNcCurvesDict[comparisonColor] = value;
    setNcCurvesDict(newNcCurvesDict);
  };

  return (
    <TableContainer sx={{ maxHeight: '100%' }}>
      <Table stickyHeader size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ minWidth: 120, maxWidth: 170, width: 170, height: 60, backgroundColor: '#272727' }}>
              <div className={styles['value-header']}>
                <Text type="bold-11px">Value</Text>
              </div>
            </StyledTableCell>
            {parameterData.map((comparison) => (
              <StyledTableCell
                key={comparison.color}
                sx={{ minWidth: 50, maxWidth: 100, padding: '6px', backgroundColor: '#272727' }}>
                <div className={styles['comparison-header']}>
                  <span className={styles['comparison-title']}>
                    <div
                      className={styles['marker']}
                      style={{
                        backgroundColor: comparison.color,
                      }}></div>
                    <span className={styles['name']}>
                      <Text type="bold-11px">{comparison.name}</Text>
                    </span>
                  </span>
                  <TrblSelect
                    menuItems={AVAILABLE_NC_CURVES.map((nc) => ({ id: nc.name, name: nc.name }))}
                    value={ncCurvesDict[comparison.color]}
                    setValue={(value) => handleNcCurveChanged(value, comparison.color)}
                    minimal
                    style={{
                      maxWidth: '80px',
                      height: '24px',
                      fontSize: '10px',
                      backgroundColor: 'transparent',
                    }}
                  />
                </div>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {openOfficeParameters.map((parameter) => (
            <StyledTableRow key={parameter.key}>
              <StyledTableCell component="th" scope="row">
                <Text type="regular-11px">{parameter.label}</Text>
              </StyledTableCell>
              {parameter.values.map((value, i) => (
                <StyledTableCell key={`${parameter.label}_value_${i}`} align="left" sx={{ paddingLeft: '20px' }}>
                  <Text type="regular-11px">{value?.toFixed(2).replace(/\.?0+$/, '') ?? '-'}</Text>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
