export enum ValidationError {
  NotInsideModel = 'NotInsideModel',
  InsideInnerVolume = 'InsideInnerVolume',
  CloseToSurface = 'CloseToSurface',
  CloseToSource = 'CloseToPoint',
}

export type SelectedDetails = {
  type: 'SourcePoint' | 'ReceiverPoint' | 'Layer' | 'LayerGroup' | 'GridReceiver';
  id: string;
};

export type SourceParams = {
  directivityPattern: string;
  azimuth: number;
  elevation: number;
  rotation: number;
  eq: number[] | null;
  overallGain: number | null;
  correctIrByOnAxisSpl: boolean;
  useSplForSti: boolean;
  irTimeDelay: number;
  splOnAxis1m: number[] | null;
  splOnAxis1mByFrequency: { [key: string]: number } | null;
};

export type Point = {
  id: string;
  label: string;
  x?: number;
  y?: number;
  z?: number;
  isValid: boolean;
  validationError?: ValidationError;
};

export type Receiver = Point;

export type Source = Point & {
  params: SourceParams;
};

export type GridReceiver = {
  orderNumber?: number;
  label: string;
  id: string;
  gridType: string;
  userInput: GridReceiverUserInput;
  points: GridReceiverPoint[];
  geometryInfo: { [Key: string]: string };
};

export type GridReceiverUserInput = {
  centerPoint: { x: number; y: number; z: number };
  coarseness: number;
  width: number;
  length: number;
  pitch: number;
  yaw: number;
  roll: number;
};

export type GridReceiverPoint = {
  gridIndex: number;
  x: number;
  y: number;
  z: number;
};

export enum ResultsView {
  ResultsReportView = 'ResultsReportView',
  ResultsModelView = 'ResultsModelView',
  ResultsGridReceiversView = 'GridReceivers',
  ResultsReflectogramView = 'ReflectogramView',
}

export type View3DType = 'ghosted' | 'shaded' | 'wireframe';
