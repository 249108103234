import { AudioObject, AudioSettings } from '@/components/Auralizer/types';
import { createConvolverPromise } from './createConvolverPromise';

import {
  AuralizerSimulationDto,
  ReceiverConvolvers,
  SourceReceiverConvolverPairPromise,
  TaskResultsForTaskGroup,
} from '../types';

export const getSourceReceiverConvolverPairs = async (
  orderedSimulations: AuralizerSimulationDto[],
  taskResultsForReceivers: TaskResultsForTaskGroup,
  audioSettings: AudioSettings,
  playableSourceIndexes: number[]
): Promise<ReceiverConvolvers> => {
  return new Promise((resolve) => {
    if (taskResultsForReceivers && Object.keys(taskResultsForReceivers).length > 0) {
      // 1. loop through each source/receiver pair in taskResultsForReceivers
      // 2. create a promise to use for Promise.all
      // 3. use promise all to get back all the AudioObjects we want to use
      // and pair them with the right source/receiver pair
      const sourceReceiverConvolverPromises: SourceReceiverConvolverPairPromise[] = [];
      const originalSources = orderedSimulations[0].latestSimulationRun.sources;
      const originalReceivers = orderedSimulations[0].latestSimulationRun.receivers;

      Object.keys(taskResultsForReceivers).forEach((taskId, index) => {
        for (const sourceId in taskResultsForReceivers[taskId]) {
          const currentSource = orderedSimulations[index].latestSimulationRun.sources.find(
            (source) => source.id === sourceId
          );

          // Skip if the source is not playable (not audio file assigned or is muted)
          if (!playableSourceIndexes.includes(currentSource?.orderNumber ?? -1)) {
            continue;
          }

          const originalSource = originalSources.find((source) => source.orderNumber === currentSource?.orderNumber);

          for (const receiverId in taskResultsForReceivers[taskId][sourceId]) {
            const currentReceiver = orderedSimulations[index].latestSimulationRun.receivers.find(
              (receiver) => receiver.id === receiverId
            );
            const originalReceiver = originalReceivers.find(
              (receiver) => receiver.orderNumber === currentReceiver?.orderNumber
            );
            if (originalSource && originalReceiver) {
              const normFactor = audioSettings[orderedSimulations[index].id][originalSource?.id].normFactor;
              const convolverPromise: Promise<unknown> = createConvolverPromise(
                taskResultsForReceivers[taskId][sourceId][receiverId].url,
                audioSettings.firstSimNormMax.relMax,
                normFactor
              );

              sourceReceiverConvolverPromises.push({
                simulationId: orderedSimulations[index].id,
                sourceId: originalSource?.id,
                receiverId: originalReceiver.id,
                convolverPromise,
              });
            }
          }
        }
      });

      Promise.all(sourceReceiverConvolverPromises.map((pair) => pair.convolverPromise)).then(
        (audioNodes: AudioObject[]) => {
          const receiverConvolvers: ReceiverConvolvers = sourceReceiverConvolverPromises.reduce(
            (acc: ReceiverConvolvers, curr: SourceReceiverConvolverPairPromise, index: number) => {
              acc = {
                ...acc,
                [curr.simulationId]: {
                  ...acc[curr.simulationId],
                  [curr.receiverId]: {
                    ...acc[curr.simulationId]?.[curr.receiverId],
                    [curr.sourceId]: {
                      audioNodes: audioNodes[index],
                    },
                  },
                },
              };
              return acc;
            },
            {} as ReceiverConvolvers
          );

          resolve(receiverConvolvers);
        }
      );
    }
  });
};
