import { useEffect, useState } from 'react';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { StepsIndicator } from '@/components/Shared/StepsIndicator';
import { MouseStep } from './MouseStep';
import { PlayStep } from './PlayStep';
import { ReceiversStep } from './ReceiversStep';
import { SimulationStep } from './SimulationStep';
import { StepArrow } from './StepArrow';

import { getClassNameForTransition } from './utils';

import styles from './styles.module.scss';

export const TutorialPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const [selectedStep, setSelectedStep] = useState(0);
  // Indicates if we are tranitioning and in what direction
  const [transition, setTransition] = useState<'left-out' | 'left-in' | 'right-out' | 'right-in' | null>(null);

  useEffect(() => {
    // Check if the user has visited before
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      // Show the popup if the user is visiting for the first time
      setShowPopup(true);
      // Set the flag in localStorage
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const handleStepForward = () => {
    if (selectedStep !== 3) {
      setTransition('left-out');
      setTimeout(() => {
        setSelectedStep(selectedStep + 1);
        setTransition('right-in');
        setTimeout(() => {
          setTransition(null);
        }, 50); // 50ms delay. Step is placed outside of view (and then 300ms for slide-in animation (see css))
      }, 300); // 300 ms for slide-out animation (see css)
    }
  };

  const handleStepBack = () => {
    if (selectedStep !== 0) {
      setTransition('right-out');
      setTimeout(() => {
        setSelectedStep(selectedStep - 1);
        setTransition('left-in');
        setTimeout(() => {
          setTransition(null);
        }, 50); // 50ms delay. Step is placed outside of view (and then 300ms for slide-in animation (see css))
      }, 300); // 300 ms for slide-out animation (see css)
    }
  };

  const handleButtonClick = () => {
    if (selectedStep === 3) {
      setShowPopup(false);
    } else {
      handleStepForward();
    }
  };

  return (
    <>
      {showPopup && (
        <TrblPopup
          width="318px"
          hideBackdrop={false}
          aria-labelledby={'Tutorial'}
          sx={{ fontSize: '12px', backdropFilter: 'blur(5px)' }}
          open={true}>
          <form>
            <TrblPopupTitle onClose={() => setShowPopup(false)} backgroundColor="rgba(38, 38, 38, 0.98)">
              {/* Empty span to get the x-button to the right */}
              <span></span>
            </TrblPopupTitle>
            <TrblPopupContent
              minimal
              style={{ display: 'flex', flexDirection: 'row', border: 'none', boxShadow: 'none', height: 150 }}>
              <StepArrow direction={'left'} onStep={handleStepBack} hidden={selectedStep === 0} />
              {selectedStep === 0 && (
                <div className={`${styles['step-transition']} ${getClassNameForTransition(transition)}`}>
                  <SimulationStep />
                </div>
              )}
              {selectedStep === 1 && (
                <div className={`${styles['step-transition']} ${getClassNameForTransition(transition)}`}>
                  <ReceiversStep />
                </div>
              )}
              {selectedStep === 2 && (
                <div className={`${styles['step-transition']} ${getClassNameForTransition(transition)}`}>
                  <MouseStep />
                </div>
              )}
              {selectedStep === 3 && (
                <div className={`${styles['step-transition']} ${getClassNameForTransition(transition)}`}>
                  <PlayStep />
                </div>
              )}
              <StepArrow direction={'right'} onStep={handleStepForward} hidden={selectedStep === 3} />
            </TrblPopupContent>
            <TrblPopupActions>
              <div className={styles['footer']}>
                <span style={{ width: 65 }} />
                <StepsIndicator numberOfSteps={4} selectedStep={selectedStep} />
                <PrimaryButton
                  width={'65px'}
                  label={selectedStep === 3 ? 'Done' : 'Next'}
                  onClick={handleButtonClick}
                />
              </div>
            </TrblPopupActions>
          </form>
        </TrblPopup>
      )}
    </>
  );
};
