import React, { CSSProperties, ReactElement } from 'react';

import { ListSubheader, MenuItem, Select } from '@mui/material';

import { TrblChevronDownIcon } from '@/components/Icons';
import { SelectOption, SelectOptionWithGrouping } from '.';

import styles from '../styles.module.scss';

export const TrblSelect = ({
  menuItems = [],
  value,
  setValue,
  disabled = false,
  placeholder = '',
  hasCategories = false,
  endIcon,
  style,
  className,
  defaultValue,
  autoFocus,
  minimal = false,
  label,
  actionButton,
  renderMenuItem,
  renderSelectedItem,
}: {
  menuItems: SelectOption[] | SelectOptionWithGrouping[];
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  hasCategories?: boolean;
  endIcon?: ReactElement | null;
  style?: CSSProperties;
  className?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  minimal?: boolean;
  label?: string;
  actionButton?: { label: string; color: string; size?: string; icon: ReactElement; onClick: () => void };
  renderMenuItem?: (menuItem: SelectOption | SelectOptionWithGrouping, index: number) => React.ReactNode;
  renderSelectedItem?: (menuItem: SelectOption | SelectOptionWithGrouping, index: number) => React.ReactNode;
}) => {
  return (
    <div className={styles['input-div']}>
      {label && <span className={styles['label-top']}>{label}</span>}
      <Select
        inputProps={{ MenuProps: { disableScrollLock: true } }}
        autoFocus={autoFocus}
        style={{ ...style }}
        IconComponent={(props) => <TrblChevronDownIcon props={props} fill="#ADADAD" />}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '&& :focus': {
              backgroundColor: 'rgba(38, 38, 38, 0.98) !important',
            },
          },
        }}
        disabled={disabled}
        value={value || ''}
        defaultValue={defaultValue}
        displayEmpty={true} // this needs to be here so we can show a placeholder
        onChange={(event) => setValue(event.target.value as string)}
        className={`${styles['custom-select']} ${className ?? ''} ${disabled ? styles['disabled'] : ''} ${
          minimal ? styles['minimal'] : ''
        } ${label ? styles['has-label-top'] : ''}`}
        renderValue={(selected: string | null) => {
          if (selected && value !== '') {
            let selectedOptionIndex: number | null = null;
            let selectedOption: SelectOption | null = null;
            if (hasCategories) {
              for (let i = 0; i < menuItems.length; i++) {
                const item = menuItems[i] as SelectOptionWithGrouping;

                for (const option of item.options) {
                  if (option.id === selected) {
                    // Should this be the index of the group or the index of the item within the group?
                    selectedOptionIndex = i;
                    selectedOption = option;
                    break;
                  }
                }
              }
            } else {
              selectedOptionIndex = (menuItems as SelectOption[]).findIndex((item) => item.id === selected) ?? null;
              selectedOption = selectedOptionIndex !== null ? menuItems[selectedOptionIndex] : null;
            }
            return (
              <>
                {selectedOption ? (
                  renderSelectedItem ? (
                    renderSelectedItem(selectedOption, selectedOptionIndex!)
                  ) : (
                    <em>{selectedOption.name}</em>
                  )
                ) : (
                  ''
                )}

                {endIcon ? <span className={styles['custom-select-icon']}>{endIcon}</span> : null}
              </>
            );
          }
          return (
            <>
              <em className={styles['placeholder']}> {placeholder}</em>
              {endIcon ? <span className={styles['custom-select-icon']}>{endIcon}</span> : null}
            </>
          );
        }}>
        {hasCategories
          ? (menuItems as SelectOptionWithGrouping[]).map((item, index) => [
              <ListSubheader className={styles['custom-select-subheader']}> {item.name} </ListSubheader>,
              item.options.map((option: SelectOption) => (
                <MenuItem
                  disabled={option.disabled}
                  key={option.id}
                  value={option.id}
                  className={`${styles['custom-select-item']} ${styles['categories']}`}>
                  {renderMenuItem ? renderMenuItem(option, index) : option.name}
                </MenuItem>
              )),
            ])
          : (menuItems as SelectOption[]).map((item, index) => (
              <MenuItem
                disabled={item.disabled}
                key={item.id}
                value={item.id}
                className={`${styles['custom-select-item']} ${minimal ? styles['minimal'] : ''}`}>
                {renderMenuItem ? renderMenuItem(item, index) : item.name}
              </MenuItem>
            ))}
        {actionButton && (
          <MenuItem
            className={`${styles['select-action-btn']} ${actionButton.size == 'small' ? styles['small'] : ''}`}
            value={value}
            onClick={actionButton.onClick}>
            <span className={styles['label']} style={{ color: actionButton.color }}>
              {actionButton.label}
            </span>
            {actionButton.icon}
          </MenuItem>
        )}
      </Select>
    </div>
  );
};
