import { FC, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { Vector3 } from 'three';

import {
  DEFAULT_CAMERA_SETTINGS,
  DEFAULT_GL_SETTINGS,
  DEFAULT_RAYCASTER_SETTINGS,
  DEFAULT_RESIZE_SETTINGS,
  DEFAULT_SCENE_SETTINGS,
} from '../../Editor/components/Viewport/constants';
import { ViewportContent } from './ViewportContent';

import { ModelLayerGroup } from '@/context/ModelContext/types';
import { Source } from '@/types';

type ViewportProps = {
  sources: Source[];
  currentPosition: Vector3;
  lookingAt: Vector3;
  layerGroups: ModelLayerGroup[];
  layerColors: Record<string, string>;
  onModelRendered: () => void;
  updateStartAngle?: (theta: number, phi: number) => void;
};

export const Viewport: FC<ViewportProps> = ({
  sources,
  currentPosition,
  lookingAt,
  layerGroups,
  layerColors,
  onModelRendered,
  updateStartAngle,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  return (
    <Canvas
      ref={canvasRef}
      frameloop="demand"
      resize={DEFAULT_RESIZE_SETTINGS}
      id="viewport"
      scene={DEFAULT_SCENE_SETTINGS}
      raycaster={DEFAULT_RAYCASTER_SETTINGS}
      camera={{
        ...DEFAULT_CAMERA_SETTINGS,
        fov: 60,
      }}
      gl={DEFAULT_GL_SETTINGS}>
      <ViewportContent
        canvasRef={canvasRef}
        updateStartAngle={updateStartAngle}
        sources={sources}
        currentPosition={currentPosition}
        lookingAt={lookingAt}
        layerGroups={layerGroups}
        layerColors={layerColors}
        onModelRendered={onModelRendered}
      />
    </Canvas>
  );
};
