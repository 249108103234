import { MutableRefObject, useEffect, useRef } from 'react';
import { HOARenderer } from 'omnitone/build/omnitone.min.esm.js';

import { SORTED_SOUNDS } from '@/components/Auralizer/constants/audioSounds';
import { SourceSettings } from '@/components/AuralizerPresets/types';
import { StandaloneAudioEngine } from '@/components/AuralizerStandalone/StandaloneAudioEngine';

import { config } from '@/__config__/config';

import { AuralizerSimulationDto } from '../types';

const { cdnUrl } = config;

type AudioSourceProps = {
  selectedSimulation: AuralizerSimulationDto | undefined;
  sourceId: string;
  sourceSettings: SourceSettings;
  masterVolume: number;
  isPlayingAudio: boolean | undefined;
  selectedReceiverId: string;
};

export const AudioSource = ({
  selectedSimulation,
  sourceId,
  sourceSettings,
  masterVolume,
  isPlayingAudio,
  selectedReceiverId,
}: AudioSourceProps) => {
  const audioRef = useRef<null | HTMLAudioElement>(null);
  const gainNodeRef: MutableRefObject<null | GainNode> = useRef<GainNode>(null);
  const mediaElementSourceRef: MutableRefObject<null | MediaElementAudioSourceNode> =
    useRef<MediaElementAudioSourceNode>(null);
  const storedInputGain: MutableRefObject<null | GainNode> = useRef<GainNode>(null);
  const storedMergerNode: MutableRefObject<null | ChannelMergerNode> = useRef<ChannelMergerNode>(null);

  const audioEngine = StandaloneAudioEngine.getInstance();

  const { audioContext, soaRenderer, setScaledNormFactor } = audioEngine;

  useEffect(() => {
    // audioRef.current is the audio element
    // The <audio> element is represented in the DOM by an object of type HTMLMediaElement,
    // which comes with its own set of functionality. All of this has stayed intact; we are merely
    // allowing the sound to be available to the Web Audio API.
    if (audioRef.current && audioContext && soaRenderer && mediaElementSourceRef.current === null) {
      // connect the audio element to the audio context
      const mediaElementSource = audioContext.createMediaElementSource(audioRef.current);

      const gainNode = audioContext.createGain();
      // @ts-expect-error channelCount is set here but doesn't exist on this type
      gainNode.gain.channelCount = 1;
      mediaElementSource.connect(gainNode);
      mediaElementSourceRef.current = mediaElementSource;
      gainNodeRef.current = gainNode;
    }
  }, []);

  useEffect(() => {
    if (selectedSimulation && selectedReceiverId && sourceId) {
      setScaledNormFactor(masterVolume, selectedSimulation.id, sourceId, selectedReceiverId);
    }
  }, [masterVolume, selectedSimulation, selectedReceiverId, sourceId]);

  useEffect(() => {
    if (sourceSettings && gainNodeRef.current && audioRef.current) {
      if (sourceSettings.isMuted) {
        // set single volume
        gainNodeRef.current.gain.value = 0.0;
      } else {
        // set single volume
        const unitValue = Math.pow(10, sourceSettings.volume / 20);
        gainNodeRef.current.gain.value = unitValue;
      }

      // set single sound
      const librarySound = SORTED_SOUNDS.find((sound) => sound.id === sourceSettings.soundPath);
      if (librarySound) {
        const newSoundPath = `${cdnUrl}${librarySound.soundPath}`;
        audioRef.current.src = newSoundPath;
      }
    }
  }, [sourceSettings]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (isPlayingAudio && audioElement && audioContext) {
      if (audioContext && audioContext.state === 'suspended') {
        audioContext.resume().catch((error) => console.error('Error resuming AudioContext:', error));
      }

      audioElement.play();
    } else if (isPlayingAudio === false && audioElement) {
      audioElement.pause();
    }
  }, [isPlayingAudio]);

  useEffect(() => {
    if (soaRenderer && gainNodeRef.current && isPlayingAudio && selectedSimulation) {
      updateReverb(soaRenderer, gainNodeRef.current, selectedSimulation?.id, selectedReceiverId, sourceId);
    }
  }, [isPlayingAudio, selectedSimulation, selectedReceiverId, sourceId]);

  const updateReverb = (
    soaRenderer: HOARenderer,
    gainNode: GainNode,
    simulationId: string,
    receiverId: string,
    sourceId: string
  ) => {
    try {
      // 1. Disconnect previously connected nodes
      if (storedInputGain.current) {
        gainNode.disconnect(storedInputGain.current);
      }
      if (storedMergerNode.current) {
        storedMergerNode.current.disconnect(soaRenderer.input);
      }
      const audioNodes = audioEngine.receiverConvolvers[simulationId][receiverId][sourceId].audioNodes;
      if (audioNodes) {
        if (audioNodes.inputGain && audioNodes.merger) {
          // 2. Connect the inputGain (receiver gain)
          gainNode.connect(audioNodes.inputGain);
          // 3. Connect merger node to soaRenderer.input
          audioNodes.merger.connect(soaRenderer.input);
          // 4. Store the new nodes to disconnect later
          storedInputGain.current = audioNodes.inputGain;
          storedMergerNode.current = audioNodes.merger;
        } else {
          console.error('Invalid audioNodes structure:', audioNodes);
        }
      } else {
        console.error('ReceiverConvolver not found for:', simulationId, selectedReceiverId, sourceId);
      }
    } catch (error) {
      console.error('Error updating reverb:', error);
    }
  };

  return <audio ref={audioRef} id={sourceId} loop crossOrigin="anonymous"></audio>;
};
