import { FC } from 'react';

import styles from './styles.module.scss';

type StepsIndicatorProps = {
  numberOfSteps: number;
  selectedStep: number;
};

export const StepsIndicator: FC<StepsIndicatorProps> = ({ numberOfSteps, selectedStep }) => {
  return (
    <div className={styles['steps']}>
      {[...Array(numberOfSteps)].map((_, i) => {
        return <span key={i} className={`${styles['step']} ${selectedStep === i ? styles['selected'] : ''}`} />;
      })}
    </div>
  );
};
