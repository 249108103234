import Omnitone, { HOARenderer } from 'omnitone/build/omnitone.min.esm.js';
import { Matrix3, Vector3 } from 'three';

import { getAudioSettingsForStandalone } from './utils/getAudioSettings';
import { getFirstSimNormMax } from './utils/getFirstSimNormMax';
import { getSourceReceiverConvolverPairs } from './utils/getSourceReceiverConvolverPairs';

import { AudioSettings } from '../Auralizer/types';
import { AuralizerSimulationDto, ReceiverConvolvers, TaskResultsForTaskGroup } from './types';

export class StandaloneAudioEngine {
  private static instance: StandaloneAudioEngine;

  private constructor() {
    this.audioContext = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: 32000,
    });
    this.soaRenderer = Omnitone.createHOARenderer(this.audioContext, {
      ambisonicOrder: 2,
    });

    this.soaRenderer.output.connect(this.audioContext.destination);
    this.soaRenderer.setRenderingMode('ambisonic');
    this.soaRenderer.initialize();
  }

  public static getInstance(): StandaloneAudioEngine {
    if (!StandaloneAudioEngine.instance) {
      StandaloneAudioEngine.instance = new StandaloneAudioEngine();
    }

    return StandaloneAudioEngine.instance;
  }

  audioContext: AudioContext;
  soaRenderer: HOARenderer;

  orderedSimulations: AuralizerSimulationDto[] | undefined;
  taskResultsForReceivers: TaskResultsForTaskGroup | undefined;
  audioSettings: AudioSettings = {} as AudioSettings;
  receiverConvolvers: ReceiverConvolvers = {} as ReceiverConvolvers;

  rotationMatrix3 = new Float32Array(9);
  rotationMatrix3js = new Matrix3();
  xBasis = new Vector3();
  yBasis = new Vector3();
  zBasis = new Vector3();

  getAudioSettingsObject = async (
    orderedSimulations: AuralizerSimulationDto[],
    taskResultsForReceivers: TaskResultsForTaskGroup
  ) => {
    let partialAudioSettings: {
      [simId: string]: {
        [sourceId: string]: {
          normFactor: number;
        };
      };
    } = {};
    const originalSources = orderedSimulations[0].latestSimulationRun.sources;
    orderedSimulations.forEach((simulation) => {
      partialAudioSettings = {
        ...partialAudioSettings,
        [simulation.id]: getAudioSettingsForStandalone(
          taskResultsForReceivers[simulation.id],
          originalSources,
          simulation.latestSimulationRun.sources
        ),
      };
    });
    // @ts-expect-error Property 'firstSimNormMax' is incompatible with index signature.
    let audioSettings: AudioSettings = {
      ...partialAudioSettings,
      firstSimNormMax: {
        relMax: 1,
        rulingSource: null,
        firstSim: null,
      },
    };

    audioSettings = getFirstSimNormMax(audioSettings, orderedSimulations[0].id);
    this.audioSettings = audioSettings;

    return audioSettings;
  };

  getReceiverConvolvers = async (
    orderedSimulations: AuralizerSimulationDto[],
    taskResultsForReceivers: TaskResultsForTaskGroup,
    audioSettings: AudioSettings,
    playableSourceIndexes: number[]
  ) => {
    const newReceiverConvolvers: ReceiverConvolvers = await getSourceReceiverConvolverPairs(
      orderedSimulations,
      taskResultsForReceivers,
      audioSettings,
      playableSourceIndexes
    );
    this.receiverConvolvers = newReceiverConvolvers;
  };

  setScaledNormFactor = (value: number, simId: string, sourceId: string, receiverId: string) => {
    const unitValue = Math.pow(10, value / 20);
    const normFactor = this.audioSettings[simId]?.[sourceId].normFactor;
    if (normFactor && this.receiverConvolvers) {
      const rescaleGainBasedOnNormFactor = this.rescaleGainBasedOnNormFactor(unitValue, normFactor);
      if (rescaleGainBasedOnNormFactor) {
        // @ts-expect-error The left-hand side of an assignment expression may not be an optional property access.
        this.receiverConvolvers[simId][receiverId][sourceId].audioNodes.inputGain.gain.value =
          rescaleGainBasedOnNormFactor;
      }
    }
  };

  updateStartAngle = (azimuth: number, elevation: number) => {
    const theta = azimuth;
    const phi = elevation;
    const psi = 0;

    // formula from https://en.wikipedia.org/wiki/Rotation_formalisms_in_three_dimensions
    // Conversion Euler angles to generic 3DoF rotation matrix
    // Note that the omnitone ambisonics matrix seems to have something peculiar so the angles need to be like this.

    const Row1 = new Vector3(
      Math.cos(theta) * Math.cos(psi),
      -Math.cos(phi) * Math.sin(psi) + Math.sin(phi) * Math.sin(theta) * Math.cos(psi),
      Math.sin(phi) * Math.sin(psi) + Math.cos(phi) * Math.sin(theta) * Math.cos(psi)
    );

    const Row2 = new Vector3(
      Math.cos(theta) * Math.sin(psi),
      Math.cos(phi) * Math.cos(psi) + Math.sin(phi) * Math.sin(theta) * Math.sin(psi),
      -Math.sin(phi) * Math.cos(psi) + Math.cos(phi) * Math.sin(theta) * Math.sin(psi)
    );

    const Row3 = new Vector3(-Math.sin(theta), Math.sin(phi) * Math.cos(theta), Math.cos(phi) * Math.cos(theta));

    // Set in row-major order

    this.rotationMatrix3js.set(Row1.x, Row1.y, Row1.z, Row2.x, Row2.y, Row2.z, Row3.x, Row3.y, Row3.z);

    // extract basis (column vectors)
    // a, d, g
    // b, e, h
    // c, f, i

    this.rotationMatrix3js.extractBasis(this.xBasis, this.yBasis, this.zBasis);

    this.rotationMatrix3[0] = this.xBasis.x;
    this.rotationMatrix3[1] = this.yBasis.x;
    this.rotationMatrix3[2] = this.zBasis.x;
    this.rotationMatrix3[3] = this.xBasis.y;
    this.rotationMatrix3[4] = this.yBasis.y;
    this.rotationMatrix3[5] = this.zBasis.y;
    this.rotationMatrix3[6] = this.xBasis.z;
    this.rotationMatrix3[7] = this.yBasis.z;
    this.rotationMatrix3[8] = this.zBasis.z;

    this.soaRenderer.setRotationMatrix3(this.rotationMatrix3);
  };

  private rescaleGainBasedOnNormFactor = (unitValue: number, scaling: number) => {
    const originalMax = this.audioSettings.firstSimNormMax.relMax;
    if (originalMax) {
      const ratio = scaling / originalMax;
      return unitValue * ratio;
    }
  };
}
