import { FC } from 'react';

import { Text } from '@/components/Shared/Text';
import { TrblPlayIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const PlayStep: FC = () => {
  return (
    <div className={styles['step']}>
      <div className={styles['controls']}>
        <div className={`${styles['button']} ${styles['play']}`}>
          <TrblPlayIcon width="16" height="16" />
        </div>
      </div>
      <Text type="regular-12px" style={{ textAlign: 'center', lineHeight: '160%', letterSpacing: '0.24px' }}>
        When you're ready to begin, <br />
        simply click the 'Play' button.
      </Text>
    </div>
  );
};
