import { FC, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFieldArrayRemove,
  useForm,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { startCase } from 'lodash';
import * as yup from 'yup';

import { Box, Stack } from '@mui/material';

import { PrimaryButton, SecondaryButton, TrblIconButton } from '@/components/Shared/Buttons';
import { DatePicker } from '@/components/Shared/DatePicker';
import { Divider } from '@/components/Shared/Divider';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';
import { TextArea } from '@/components/Shared/TextArea';
import { TextField as TrblTextField } from '@/components/Shared/TextField';
import { SelectOption, TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblTooltip } from '@/components/Shared/TrblTooltip';
import { TrblDeleteIcon } from '@/components/Icons/TrblDeleteIcon';

import { Product, UserDto } from '../../hooks';

import classes from './styles.module.scss';

type CreateOrganization = {
  id?: string;
  name: string;
  description: string;
  products: Product[];
};

type OrganizationFormProps = {
  organization?: CreateOrganization;
  onCancel: () => void;
  onSubmit: (values: OrganizationFormState) => void;
};

export type OrganizationFormState = {
  id?: string;
  name: string;
  description: string;
  products?: Product[];
  initialUser?: UserDto;
  tokens: number;
};

type ProductFormProps = {
  index: number;
  control: Control<OrganizationFormState>;
  watch: UseFormWatch<OrganizationFormState>;
  remove: UseFieldArrayRemove;
  trigger: UseFormTrigger<OrganizationFormState>;
  productKeys: SelectOption[];
  onChangeProductKeys: () => void;
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  tokens: yup.number().min(0),
  products: yup.array().of(
    yup.object().shape({
      productKey: yup.string().ensure().required(),
      billingType: yup.string().ensure().required(),
      expiresAt: yup
        .string()
        .nullable()
        .when('billingType', (billingType, schema) => {
          if (billingType !== 'Trial') {
            return schema.required();
          }
          return schema;
        }),
    })
  ),
});

const getErrorMessage = (errors: FieldErrors<OrganizationFormState>) => {
  // find field names that have an error to include in the error message
  let fields = Object.keys(errors);
  if (fields.includes('products') && !!errors?.products && Array.isArray(errors.products)) {
    const productFields = new Set<string>(
      errors.products
        .filter((f) => !!f)
        .map(Object.keys)
        .flat()
    );
    // add the nested fields but leave out the parent property "products"
    fields = [...fields.filter((f) => f !== 'products'), ...productFields];
  }
  if (!fields.length) {
    return '';
  }
  return `Required fields: ${fields.map((f) => startCase(f)).join(', ')}`;
};

const numberInputStyle = { width: '180px', paddingRight: '20px' };

const billingTypes = [
  { id: 'Trial', name: 'Trial' },
  { id: 'PrepaidOnly', name: 'Prepaid Only' },
  { id: 'Unlimited', name: 'Unlimited' },
  { id: 'OnDemandOnly', name: 'On Demand Only' },
  { id: 'Free', name: 'Free' },
];

const initialProduct = {
  productKey: '',
  billingType: '',
  paymentFrequency: '',
  concurrency: 10,
  activeSeats: 0,
  maxSeats: 20,
  expiresAt: null,
  sdkMaxCpuConcurrency: 1,
  sdkMaxGpuConcurrency: 1,
  sdkMaxGpuCountPerTask: 1,
};

const ProductForm: FC<ProductFormProps> = ({
  control,
  index,
  watch,
  remove,
  trigger,
  productKeys,
  onChangeProductKeys,
}) => {
  const watchProducts = watch('products');
  const isSDK = !!watchProducts && !!watchProducts.length && watchProducts[index].productKey == 'TSDK';
  const isTASS = !!watchProducts && !!watchProducts.length && watchProducts[index].productKey == 'TASS';

  return (
    <div className={classes.form_layout}>
      <Stack flex={'auto 1 1'} spacing="20px">
        <Box component="div" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
          <Box component="div" display="flex" justifyContent="center" alignItems="center">
            Product
          </Box>
          <TrblIconButton onClick={() => remove(index)} label="Remove" icon={<TrblDeleteIcon />} />
        </Box>
        <Divider />
        <Controller
          name={`products.${index}.productKey`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TrblSelect
              value={value}
              label="Product key"
              setValue={(value) => {
                onChange(value);
                onChangeProductKeys();
              }}
              menuItems={productKeys}
              placeholder="Select product key"
            />
          )}
        />
        <Controller
          name={`products.${index}.billingType`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TrblSelect
              value={value}
              label="Billing type"
              setValue={(value) => {
                onChange(value);
                trigger(`products.${index}.expiresAt`);
              }}
              menuItems={billingTypes}
              placeholder="Select billing type"
            />
          )}
        />
        <Controller
          name={`products.${index}.expiresAt`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box component="div" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
              <Box component="div" display="flex" justifyContent="center" alignItems="center">
                <Text type={'regular-12px'}>Expires at</Text>
              </Box>
              <Box component="div" width="180px">
                <DatePicker value={value || null} minDate={dayjs()} onChange={onChange} />
              </Box>
            </Box>
          )}
        />
        {(isTASS || isSDK) && (
          <Controller
            name={`products.${index}.maxSeats`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TrblNumberInput
                style={numberInputStyle}
                value={Number(value)}
                onChange={onChange}
                label="Max seats"
                min={0}
                max={isSDK ? 20 : undefined}
              />
            )}
          />
        )}
        {isTASS && (
          <Controller
            name={`products.${index}.concurrency`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TrblNumberInput
                style={numberInputStyle}
                value={Number(value)}
                onChange={onChange}
                label="Concurrency"
                min={0}
              />
            )}
          />
        )}
        {isSDK && (
          <>
            <Controller
              name={`products.${index}.sdkMaxCpuConcurrency`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TrblNumberInput
                  style={numberInputStyle}
                  value={Number(value)}
                  onChange={onChange}
                  label="Max CPU concurrency"
                  min={1}
                  max={100}
                />
              )}
            />
            <Controller
              name={`products.${index}.sdkMaxGpuConcurrency`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TrblNumberInput
                  style={numberInputStyle}
                  value={Number(value)}
                  onChange={onChange}
                  label="Max GPU concurrency"
                  min={1}
                  max={100}
                />
              )}
            />
            <Controller
              name={`products.${index}.sdkMaxGpuCountPerTask`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TrblNumberInput
                  style={numberInputStyle}
                  value={Number(value)}
                  onChange={onChange}
                  label="Max GPU count per task"
                  min={1}
                  max={8}
                />
              )}
            />
          </>
        )}
      </Stack>
    </div>
  );
};

export const OrganizationForm: FC<OrganizationFormProps> = ({ organization, onCancel, onSubmit }) => {
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors, isValid },
    trigger,
  } = useForm<OrganizationFormState>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: organization?.id,
      name: organization?.name || '',
      description: organization?.description || '',
      products: organization?.products || [],
      tokens: 0,
      initialUser: {
        email: '',
      },
    },
    mode: 'all',
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'products' });
  const watchProducts = watch('products');

  const [productKeys, setProductKeys] = useState<SelectOption[]>([
    { id: 'TASS', name: 'Treble web app', disabled: false },
    { id: 'TSDK', name: 'SDK', disabled: false },
  ]);

  const onChangeProductKeys = () => {
    if (!watchProducts) {
      return;
    }
    const keysInUse = watchProducts.filter((p) => !!p.productKey).map((p) => p.productKey);
    setProductKeys(
      productKeys.map((key) => {
        if (keysInUse.includes(key.id)) {
          // each product key can only be used once
          key.disabled = true;
          return key;
        }
        key.disabled = false;
        return key;
      })
    );
  };

  useEffect(() => {
    onChangeProductKeys();
  }, [watchProducts]);

  useEffect(() => {
    if (organization?.id) {
      register('id', { value: organization.id });
    }
  }, [organization]);

  useEffect(() => {
    // trigger validation on mount
    trigger();
  }, [trigger]);

  return (
    <TrblPopup width={600} maxheight={'90vh'} minheight={'400px'} aria-labelledby={'Create new material'} open={true}>
      <TrblPopupTitle onClose={() => onCancel()}>
        {organization ? 'Update organization' : 'New organization'}
      </TrblPopupTitle>
      <TrblPopupContent>
        <div className={classes.form_layout}>
          <Stack flex={'auto 1 1'} spacing="20px">
            <Controller
              name={'name'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TrblTextField label="Organization name" autoFocus onChange={onChange} value={value} />
              )}
            />
            <Controller
              name={'description'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextArea label="Description" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name={'tokens'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TrblNumberInput
                  style={numberInputStyle}
                  value={Number(value)}
                  onChange={onChange}
                  label="Tokens"
                  min={0}
                  max={75000}
                />
              )}
            />
            <h2 style={{ margin: '32px 0 4px', fontSize: '14px' }}>Initial user</h2>
            <Controller
              name={'initialUser.email'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TrblTextField label="Email" onChange={onChange} value={value} />
              )}
            />
            <h2 style={{ margin: '32px 0 4px', fontSize: '14px' }}>Products</h2>
            {fields.map((item, index) => {
              return (
                <div key={item.id}>
                  <ProductForm
                    control={control}
                    index={index}
                    watch={watch}
                    remove={remove}
                    trigger={trigger}
                    productKeys={productKeys}
                    onChangeProductKeys={onChangeProductKeys}
                  />
                </div>
              );
            })}
            {!!watchProducts && watchProducts.length < 2 && (
              <SecondaryButton
                label="Add product"
                onClick={() => {
                  append(initialProduct);
                  trigger();
                }}
              />
            )}
          </Stack>
        </div>
      </TrblPopupContent>
      <TrblPopupActions framed>
        <Box component="div" width="150px">
          <SecondaryButton label="Cancel" onClick={onCancel} />
        </Box>
        <TrblTooltip title={!isValid ? getErrorMessage(errors) : ''}>
          <Box component="div" width="150px">
            <PrimaryButton
              label={organization ? 'Update' : 'Create'}
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            />
          </Box>
        </TrblTooltip>
      </TrblPopupActions>
    </TrblPopup>
  );
};
