import { UseQueryResult } from '@tanstack/react-query';

import { toSorted } from '@/utils/trebleFunctions';

import { SolveTask, SourceResults } from '@/types';

export const getOrderedSourceResults = (results: UseQueryResult<SolveTask, unknown>[]): SourceResults[] | undefined => {
  if (results?.[0]?.data) {
    const orderedSourceResults = toSorted(results[0].data.sourceResults, (a, b) => a.orderNumber - b.orderNumber);
    return orderedSourceResults;
  }
};
